<template>
  <div
    class="DefaultLayout">
    <slot/>
  </div>
</template>


<script>
export default {
  name: 'DefaultLayout',
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.DefaultLayout {
  min-height: 100vh;
}
</style>
